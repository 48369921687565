.wrapper {
  width: 100%;
  min-height: 110px;
  padding: 20px 34px 19px 19px;
  display: flex;
  justify-content: space-between;
  gap: 17px;
  border-radius: 20px;
  background-color: #131314;
  max-width: 929px;
  height: 100%;

  @media (max-width: 1100px) {
    max-width: 655px;
    flex-wrap: wrap;
  }
}

.contentContainer {
  display: flex;
  gap: 17px;
}

.iconWrapper {
  width: 37px;
  height: 37px;
  display: flex;
  flex-shrink: 0;
  margin-top: 7px;
}
.contentWrapper {
  width: 634px;
  @media (max-width: 1100px) {
    width: 100%;
  }
}

.buttonWrapper {
  margin-top: 21px;
  width: 165px;
  transition: all 0.2s;

  button {
    span {
      font: 13px var(--visby-bold);
    }
  }

  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 460px) {
    margin-top: 26px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
}

.descWrapper {
  margin-top: 7px;
  max-width: 520px;
  @media (max-width: 1100px) {
    max-width: unset;
    width: 100%;
  }
}

.desc {
  margin-top: 7px;
  font: 13px/21px var(--visby);
  color: #ADADAD;
}
