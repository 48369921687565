.modal {
  & :global(.ant-modal-content) {
    border-radius: 30px;
    background-color: transparent;
  }
  & :global(.ant-modal-body) {
    padding: 0;
  }
  & :global(.ant-modal-close) {
    color: #ffffff;
  }
  & :global(.ant-modal-close-x) {
    font: 18px var(--visby-bold);
    margin-top: 20px;
    margin-right: 10px;
    @media (max-width: 380px) {
      margin-right: 0;
    }
  }
}

.calendarWrapper > div > div:nth-child(1) > div {
  @media (max-width: 460px) {
    max-width: 255px;
  }
  @media (max-width: 370px) {
    max-width: 160px;
  }
  @media (max-width: 310px) {
    max-width: 140px;
    font-size: 18px;
  }
}
