.bannerWrapper {
  margin-bottom: 24px;
  max-width: 645px;
  min-height: 110px;
  padding: 21px 22px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 51px auto;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: 1px solid #00fca3;
}

.textSide {
  h3 {
    font: 16px/16px var(--visby-bold);
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 2px;
  }
  p,
  a {
    font: 16px/24px var(--visby-medium);
    color: #ADADAD;
    font-weight: 400;
    margin-bottom: 0px;
  }
  a {
    color: #00fca3;
    text-decoration: underline;
  }
}
