.wrapper {
  background-color: #131314;
  border-radius: 20px;
  padding: 28px 33px 30px 24px;
  width: 100%;
  @media (max-width: 390px) {
    padding: 28px 15px 30px 24px;
  }
}

.quizWrapper {
  padding: 28px 33px 30px 24px;
  @media (max-width: 390px) {
    padding: 28px 15px 30px 24px;
  }
}

.topWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 54px 240px;
  @media (max-width: 403px) {
    grid-template-columns: 54px auto;
  }
  @media (max-width: 300px) {
    grid-template-columns: 49px auto;
  }
}

.numberWrapper {
  background-color: #00fca3;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font: 18px var(--visby-bold);
    color: #252342;
  }
}

.title {
  color: #ffffff;
  font: 28px/29px var(--visby-semiBold);
  @media (max-width: 365px) {
    font-size: 18px;
  }
}

.gamesTitle {
  grid-column: 2/4;
}

.quizTitle {
  margin-left: 53px;
  margin-top: 22px;
  @media (max-width: 390px) {
    margin-left: 0px;
  }
}

.text {
  margin-left: 54px;
  margin-top: 35px;
  div > p {
    font: 15px/21px var(--visby);
    color: #adadad;
  }
  @media (max-width: 390px) {
    margin-left: 0px;
  }
}

.quizText {
  margin-top: 13px;
}

.additionalMargin {
  margin-left: 53px;
  @media (max-width: 390px) {
    margin-left: 0px;
  }
}

.buttonWrapper {
  button {
    margin-bottom: 10px;
    max-width: 165px;
    height: 42px;
    span {
      font: 13px var(--visby-extraBold);
    }
    @media (max-width: 390px) {
      max-width: unset;
      width: 100%;
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.countDownText {
  background-color: #fc0;
  min-width: 235px;
  border-radius: 20px;
  padding: 3px 14px 2px 14px;
  color: #131314;
  font: 12px/18px var(--visby-bold);
  div {
    font: 12px/18px var(--visby-bold);
    color: #131314;
    @media (max-width: 365px) {
      width: 60px;
    }
  }
  @media (max-width: 365px) {
    min-width: unset;
    text-align: center;
    height: 45px;
    border-radius: 40px;
  }
}

.quizNotStartedText {
  background-color: #787878;
  border-radius: 20px;
  padding: 3px 9px 2px 9px;
  color: #131314;
  font: 12px/18px var(--visby-bold);
  max-width: 125px;
}

.quizStartedText {
  background-color: #fc0;
  border-radius: 20px;
  padding: 3px 14px 2px 14px;
  color: #131314;
  font: 12px/18px var(--visby-bold);
  max-width: 120px;
}

.gamesLayout {
  margin-top: 14px;
  display: grid;
  gap: 57px;
  grid-template-columns: 584px 214px;
  @media (max-width: 1065px) {
    grid-template-columns: auto 214px;
  }
  @media (max-width: 890px) {
    grid-template-columns: auto;
  }
}

.gamesText {
  margin-top: 21px;
}

.logoWrapper {
  @media (max-width: 890px) {
    display: none;
  }
}
