.wrapper {
  display: inline-flex;
  padding: 22px 0;
}

.logo {
  padding: 0;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    background: none;
  }

  .logo img {
    display: block;
  }
}
