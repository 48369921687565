.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 40px auto 56px;
  padding: 11px 16px 10px 18px;
  background-color: #131314;
  border-radius: 10px;
  align-items: center;
  column-gap: 14px;
  @media (max-width: 450px) {
    align-items: flex-start;
    padding: 11px 16px 20px 18px;
  }
}

.listCountWrapper {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: relative;
}

.listCount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #00fca3;
  font: 23px/23px var(--visby-bold);
  padding-top: 1px;
  box-shadow: 0px 10px 20px #000;
}

.title {
  font: 13px/21px var(--visby-bold);
  color: #ffffff;
  width: 100%;
  text-overflow: ellipsis;
  display: flex;
  align-self: center;
  @media (max-width: 400px) {
    font: 12px/21px var(--visby-bold);
  }
}

.videoIconWrapper {
  width: 40px;
  margin-left: 16px;
  @media (max-width: 450px) {
    margin-top: 7px;
  }
}

.shortDescriptionText {
  margin-bottom: 10px;
  margin-left: 72px;
  margin-right: 70px;
  margin-bottom: 30px;
  color: #ADADAD;
  font: 13px/21px var(--visby);
}

.container {
  cursor: pointer;
  height: 100%;
  background-color: #141516;
  border-radius: 10px;
  border: 1px solid #141516;
  &:hover {
    .shortDescription {
      max-height: 150px;
      transition: max-height 1s ease;
    }
  }
  &:hover,
  &:active {
    border: 1px solid #00fca3;
    .shortDescriptionText {
      color: #ffffff;
    }
    .wrapper {
      background-color: #131314;
    }
    .title {
      color: #ffffff;
    }
    .videoIconWrapper {
      svg {
        fill: #00fca3;
      }
    }
  }
  @media (max-width: 1000px) {
    height: unset;
    min-height: 114px;
  }
}
