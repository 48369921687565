.container {
  position: absolute;
  height: 100%;
  top: 140px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 600px) {
    top: 20px;
  }
}

.wrapper {
  max-width: 400px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 30px;
  font: 42px/46px var(--visby-bold);
  color: #ffffff;

  @media (max-width: 580px) {
    font-size: 32px;
    line-height: 36px;
  }
}

.descWrapper {
  margin-top: 26px;
}

.desc {
  font: 17px/26px var(--visby-medium);
  color: #ffffff;
}

.iconWrapper {
  svg {
    width: 96px;
    height: auto;
    path {
      fill: #00fca3;
    }
  }

  width: 100px;
  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
