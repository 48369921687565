.wrapper {
  background-color: #131314;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 0 40px 40px;
  border-radius: 20px 0 0 20px;
}

.headerWrapper {
  margin-bottom: 78px;
}

.step {
  margin-bottom: 0;
  font: 11px/17px var(--visby);
  color: #ffffff;
  background: transparent;
  padding: 0px 26px 0px 0px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
  display: grid;
  grid-template-columns: 18px 230px;
  grid-template-rows: 33px 17px;
  column-gap: 13px;
}

.circleSmall {
  z-index: 99999;
  display: grid;
  justify-items: center;
  align-items: flex-end;
  grid-column: 1;
  grid-row: 2;
  circle {
    stroke-width: 2;
  }
}

.stepActive {
  color: #00fca3;
  .circleWrapper {
    width: 16px;
    height: 16px;
    circle {
      stroke: #00fca3;
      stroke-width: 2;
    }
    svg {
      border-radius: 55%;
      box-shadow: 0px 0px 13px #00fca3;
    }
  }
  .lineWrapper {
    rect {
      fill: #00fca3;
    }
  }
  .circleSmall {
    path {
      stroke: #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: 0px 0px 13px #00fca3;
    }
  }
}

.stepCompleted {
  color: #00fca3;
  .circleWrapper {
    width: 16px;
    height: 16px;
    border-radius: 55%;
    circle {
      stroke: #00fca3;
      stroke-width: 2;
    }
    path {
      fill: #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: none;
    }
  }
  .lineWrapper {
    rect {
      fill: #00fca3;
    }
  }
  .circleSmall {
    path {
      stroke: #00fca3;
    }
    svg {
      border-radius: 55%;
      box-shadow: 0px 0px 13px #00fca3;
    }
  }
}

.circleWrapper {
  z-index: 99999;
  display: grid;
  justify-items: center;
  align-items: flex-end;
  grid-column: 1;
  grid-row: 2;
  circle {
    stroke-width: 2;
  }
}

.lineWrapper {
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
}

.titleWrapper {
  grid-column: 2;
  grid-row: 2;
}
