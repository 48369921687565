.wrapper {
  width: 100%;
  height: 100%;
  & :global(.ant-input) {
    width: 100%;
    height: 100%;
    margin-top: 9px;
    border: 1px solid #476268;
    border-radius: 10px;
    font: 16px/24px var(--visby);
    color: rgba(54, 54, 54, 1);
    padding: 12px 16px;
    resize: none;
    &:hover,
    &:focus {
      border: 1px solid #476268;
      box-shadow: unset;
    }
  }
}
