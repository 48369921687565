.customButton {
  &:global(.ant-btn) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42px;
    border-radius: 40px;
    background: #00fca3;
    min-height: 38px;
    font: 16px/24px var(--visby-extraBold);
    border: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 10px;
    &:hover,
    &:focus,
    &:active {
      background: #00fca3;
      color: #252342;
      border: none;
    }
    @media (max-width: 400px) {
      font: 14px var(--visby-extraBold);
    }
    @media (max-width: 360px) {
      font: 12px var(--visby-extraBold);
      padding: 0 5px;
    }
    & svg {
      margin-right: 17px;

      @media (max-width: 360px) {
        margin-right: 8px;
      }
    }
    & svg path {
      margin-right: 17px;
      stroke: rgba(37, 35, 66, 1);
    }
    &:global(.ant-btn[disabled]) {
      border: none;
      background: #606265;
      color: #131314;
    }
  }

  &:global(.ant-btn-ghost) {
    background: none;
    color: #ffffff;
    border: 1px solid #00fca3;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover,
    &:focus,
    &:active {
      background: none;
      color: #ffffff;
      border: 1px solid #00fca3;
    }
    svg path {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      stroke: #ffffff;
    }
  }
  &:global(.ant-btn-primary) {
    background: white;
    color: #252342;
    border: 1px solid #00fca3;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:global(.ant-btn-text) {
    background: #7251F9;
    color: #ffffff;
    border: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover,
    &:focus,
    &:active {
      background: #7251F9;
      color: #ffffff;
      border: none;
    }
    & svg path {
      stroke: #ffffff;
    }
    &:disabled {
      background: #85979b;
      color: #bdbdbd;
      & svg * {
        stroke: #bdbdbd;
      }
    }
  }
  &:global(.ant-btn-link) {
    background-color: transparent;
    border: 1px solid #7251F9;
    font: 16px/24px var(--visby-extraBold);
    color: #7251F9;
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border: 1px solid #00fca3;
      color: #00fca3;
      stroke: red;
  
    }
    & svg * {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      stroke: #ffffff;
    }
  }
  &:global(.ant-btn-dashed) {
    background: #131314;
    color: #ffffff;
    font: 13px/21px var(--visby-extraBold);
    &:hover,
    &:focus,
    &:active {
      background: #131314;
      color: #ffffff;
      border: none;
    }
  }
}
