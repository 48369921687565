.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.desc {
  font: 12px/20px var(--visby-bold);
  color: #ffffff;
  margin-right: 16px;
  cursor: pointer;
  padding: 5px 10px 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 20px;

  &:hover {
    padding: 5px 10px 5px;
    background-color: #2b2e31;
  }
  &:active {
    padding: 5px 10px 5px;
    background-color: #2b2e31;
  }
}

.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  background-color: #2b2e31;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: unset;
  }
  &:active {
    background-color: unset;
  }
}
