.wrapper {
  background-color: #131314;
  display: grid;
  grid-template-columns: 34px auto;
  grid-template-rows: auto;
  column-gap: 20px;
  border-radius: 20px;
  padding: 14px 46px 18px 19px;
  margin-bottom: 20px;
  @media (max-width: 360px) {
    padding-right: 19px;
    column-gap: 15px;
  }
}

.wrapperWithButton,
.wrapperCompleted {
  grid-template-columns: 34px auto 165px;
}

.wrapperWithButton {
  @media (max-width: 360px) {
    grid-template-columns: 34px auto auto;
  }
}

.wrapperCompleted {
  background-color: rgba(0, 252, 163, 0.12);
  @media (max-width: 900px) {
    grid-template-columns: 34px auto auto;
  }
}


.numberWrapper {
  background-color: #00fca3;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  span {
    font: 18px var(--visby-bold);
    color: #252342;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  align-self: center;
  @media (max-width: 460px) {
    font-size: 18px;
  }
}

.text {
  margin-top: 13px;
  font: 13px/21px var(--visby);
  color: #adadad;
}

.mainWrapper {
  grid-row: 1;
  grid-column: 2;
  @media (max-width: 750px) {
    grid-column: 2/4;
  }
}

.mainWrapperCompleted {
  @media (max-width: 750px) {
    grid-column: 2;
  }
}

.buttonWrapper {
  grid-row: 1;
  grid-column: 3;
  display: flex;
  align-self: center;
  button {
    span {
      font: 13px var(--visby-bold);
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 750px) {
    margin-top: 20px;
    grid-row: 2;
    grid-column: 2/4;
  }
}

.imageWrapper {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  @media (max-width: 600px) {
    align-self: flex-start;
  }
  @media (max-width: 460px) {
    display: none;
  }
}
