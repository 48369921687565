.container {
  max-width: 295px;
  width: 100%;
  height: 213px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #00fca3;
  cursor: pointer;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 340px;
    height: 100%;
    flex-direction: column;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
}

.topWrapper {
  padding: 18px 24px 10px 14px;
  background-color: #00fca3;
  height: 104px;
  width: 295px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    width: 100%;
  }
}

.disabled {
  background-color: #292f37;
  cursor: not-allowed;
}

.disabled {
  &:hover {
    transform: unset;
    box-shadow: unset;
  }

  &:active {
    box-shadow: unset;
    transform: unset;
  }
}

.numberWrapper {
  width: 68px;
  height: 68px;
  background-color: #131314;
  position: relative;
  border-radius: 100px;
}

.number {
  font: 36px/36px var(--visby-bold);
  margin-top: 2px;
  color: #00fca3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.number {
  color: #00fca3;
}

.lengthWrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 5px;
}

.videoIconWrapper {
  align-self: flex-end;
  width: 40px;
  path {
    fill: #131314;
  }
}

.lengthDescWrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: end;
  svg {
    width: 14px;
    height: 14px;
  }
}

.lengthDesc {
  font: 13px/21px var(--visby);
  color: #131314;
}

.bottomWrapper {
  padding: 14px 14px 3px 20px;
  background-color: #ffffff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 109px;
  width: 295px;
  transition: background-image 2s;

  @media (max-width: 720px) {
    max-width: 340px;
    width: 100%;
    padding-bottom: 20px;
  }
}

.lessonTitle {
  font: 19px/24px var(--visby-medium);
  color: #131314;
  text-align: start;
}

.currentLesson {
  color: #ffffff;
}
