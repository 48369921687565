.wrapper,
.wrapperAllowed {
  display: flex;
  align-items: center;
  width: 108px;
  height: 38px;
  align-items: center;
  background-color: #1c1f23;
  border-radius: 7px;
  border: 1px solid #00fca3;
  padding: 8px 9px;
}
.wrapperAllowed {
  cursor: pointer;
}

.iconWrapper {
  width: 19px;
  height: 19px;
}

.iconDesc {
  font: 10px var(--visby-semiBold);
  color: #ffffff;
  margin-left: 6px;
}
