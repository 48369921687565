.container {
  background-color: #193a32;
  border-radius: 20px;
  padding: 9px 16px 21px 13px;
  .text {
    font: 13px/21px var(--visby);
    max-width: 440px;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
  margin-bottom: 9px;
  @media (max-width: 310px) {
    font: 17px/21px var(--visby-semiBold);
  }
}

.text {
  font: 17px/29px var(--visby);
  color: #ADADAD;
  max-width: 520px;
  margin-top: 10px;
  @media (max-width: 920px) {
    margin-top: 0px;
    max-width: unset;
  }
}

.numberWrapper {
  background-color: #00fca3;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font: 18px var(--visby-bold);
    color: #252342;
  }
}

.status {
  background-color: #00fca3;
  border-radius: 20px;
  padding: 3px 14px 2px 14px;
  color: #131314;
  font: 12px/18px var(--visby-bold);
  max-width: 105px;
  height: 23px;
}

.topWrapper {
  display: flex;
  justify-content: space-between;
}

.bottomWrapper {
  margin-left: 19px;
  margin-top: 18px;
}

.numberAndStatusWrapper {
  display: flex;
  gap: 19px;
  align-items: center;
  margin-top: 8px;
}
