.wrapper {
  width: 455px;
  height: 298px;
  padding: 29px 36px 51px 38px;
  border-radius: 20px;
  background-color: #131314;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1100px) {
    width: 655px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconWrapper {
  width: 52px;
  height: 52px;
  display: flex;
  flex-shrink: 0;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.buttonWrapper {
  margin-top: 21px;
  width: 165px;
  transition: all 0.2s;

  button {
    span {
      font: 13px var(--visby-bold);
    }
  }

  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
  @media (max-width: 460px) {
    margin-top: 26px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
}

.descWrapper {
  margin-top: 7px;
  max-width: 520px;
  @media (max-width: 1100px) {
    max-width: unset;
    width: 100%;
  }
}

.desc {
  margin-top: 7px;
  font: 13px var(--visby);
  color: #ADADAD;
  line-height: 21px;
}
