.countDownWrapper {
  display: flex;
  flex-direction: column-reverse;
}
.container {
  display: flex;
  height: 120px;
  margin-left: 10px;
}

.wrapper {
  position: relative;
  height: 118px;
  width: 68.6px;
  svg {
    height: 118px;
    width: 68.6px;
  }

  @media (max-width: 388px) {
    height: 118px;
    width: 60px;
    svg {
      height: 118px;
      width: 60px;
    }
  }
}

.timeWrapper {
  max-width: 160px;
}

.time {
  font: 14px/22px var(--visby-medium);
  color: #ffffff;
  text-align: center;
}

.time {
  left: 68px;
  @media (max-width: 388px) {
    left: 38px;
  }
}

.number {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  font: 90px/90px var(--visby-semiBold);
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 388px) {
    font-size: 72px;
    line-height: 72px;
  }
}

.numberRed {
  color: #ec4339;
}
