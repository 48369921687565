.step {
  max-width: 581px;
  margin-left: 7px;
  color: #ffffff;
}

.secondaryStep {
  color: #ffffff;
}

.defaultStep {
  color: #000;
}

.title {
  font: 13px/21px var(--visby);
  margin-top: 5px;
  color: #adadad;
}

.titleSecondary {
  color: #adadad;
}

.passiveTitle {
  color: #ffffff;
}

.titleDefault {
  color: #ffffff;
}

.description {
  font: 13px/21px var(--visby);
  margin: 0 0 0 0;
  color: #ffffff;
}

.descriptionPassive {
  font: 13px/21px var(--visby);
  margin: 0 0 0 0;
  color: #ffffff;
}

.withoutTitle {
  margin: 0;
}

.btnWrapper {
  margin-top: 18px;
  height: 38px;
  max-width: 265px;
  @media (max-width: 490px) {
    max-width: unset;
  }
  a > span {
    font: 13px var(--visby-bold);
  }
  button > span {
    font: 13px var(--visby-bold);
  }
  a, button {
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }
  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
}
}

.caption {
  font: 20px/28px var(--visby-semiBold);
  text-transform: uppercase;
  color: #ffffff;
  margin: 28px 0 0 0;
  @media (min-width: 1280px) {
    margin: 18px 0 0 0;
  }
}

.captionDefault {
  font: 20px/28px var(--visby-semiBold);
  text-transform: uppercase;
  color: #ffffff;
  margin: 28px 0 0 0;
  @media (min-width: 1280px) {
    margin: 18px 0 0 0;
  }
}

.captionSecondary {
  color: #0a2e36;
}

.showUploadWrapper {
  padding-top: 16px;
  width: 100%;
}

.completedWrapper {
  display: flex;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
}
