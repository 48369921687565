.desc {
  font: 13px/21px var(--visby);
  color: #ffffff;
  margin-right: 20px;

  a {
    color: #ffffff;
    font: 13px/21px var(--visby);
    margin-left: 6px;

    &:hover {
      color: #00fca3;
      text-decoration: underline;
    }
  }
}

.linkWrapper {
  margin-top: 12px;
  display: flex;
  gap: 20px;

  a {
    font: 11px/11px var(--visby);
    color: #ffffff;
    text-decoration: underline;
    transition: all 0.2s;

    &:hover {
      color: #00fca3;
    }
  }

  @media (max-width: 334px) {
    flex-direction: column;
    gap: 12px;
  }
}

.greetingWrapper {
  margin-top: 27px;
}

.footerText {
  font: 13px/21px var(--visby);
  color: #ffffff;
  max-width: 734px;
}
