.wrapper {
  width: 100%;
  padding: 15px 30px 15px 18px;
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #00fca3;
  border-radius: 10px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
}

.icon {
  overflow: visible;
  width: 45px;
  display: flex;
  align-self: flex-start;
  margin-top: 10px;
  @media (max-width: 650px) {
    display: none;
  }
}
.descContainer {
  display: flex;
}

.descContainer,
.descContainerWithButton {
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.descContainerWithButton {
  display: grid;
  grid-template-columns: auto 144px;
  gap: 19px;
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.descWrapper {
  display: flex;
  gap: 2px;
  flex-direction: column;
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #252342;
}

.desc {
  font: 13px/21px var(--visby);
  color: #252342;
}

.button {
  width: 100%;
  padding: 10px 23px;
  background-color: #131314;
  color: #00fca3;
  border-radius: 50px;
  font: 14px/27px var(--visby-semiBold);
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#000, 0.2);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#000, 0.15);
    transform: translateY(0);
  }
  @media (max-width: 320px) {
    max-width: unset;
  }
}

.success,
.info {
  background-color: #131314;
  color: #00fca3;
}
.danger {
  background-color: #7251f9;
  color: #ffffff;
}
