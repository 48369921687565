.wrapper {
  display: grid;
  gap: 51px;
  grid-template-columns: 615px 214px;
  @media (max-width: 1015px) {
    grid-template-columns: auto 214px;
  }
  @media (max-width: 715px) {
    grid-template-columns: auto;
  }
}

.title {
  margin-top: 21px;
  color: #ffffff;
  font: 30px/36px var(--visby-medium);
  @media (max-width: 715px) {
    margin-top: 0px;
  }
}

.text {
  color: #ffffff;
  font: 13px/21px var(--visby);
  margin-top: 5px;
  margin-bottom: 15px;
  max-width: 579px;
}

.buttonWrapper {
  margin-bottom: 20px;
  width: 165px;
  button {
    span {
      font: 13px var(--visby-bold);
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.logoWrapper {
  @media (max-width: 715px) {
    display: none;
  }
}
