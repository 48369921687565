.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border: 1px solid #00fca3;
  border-radius: 20px;
  max-width: 454px;
  padding: 14px 25px;
  cursor: pointer;
  background-color: rgba(28, 31, 35, 0.273);
  opacity: 0.99;
  z-index: 10;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }
  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
  @media (max-width: 380px) {
    gap: 10px;
  }
}

.description {
  font: 13px/21px var(--visby);
  color: #ffffff;
  margin-bottom: 5px;
}

.copyWrapper {
  display: flex;
  flex-direction: column;
}

.copyIcon {
  display: flex;
  align-items: center;
  width: 21px;
}

.link {
  font: 13px/21px var(--visby-bold);
  color: #00fca3;
  align-self: center;
  overflow-wrap: break-word;
  max-width: 600px;
  @media (max-width: 434px) {
    max-width: 200px;
  }
  @media (max-width: 320px) {
    max-width: 150px;
  }
}
