.wrapper {
  margin-top: 42px;
  position: relative;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.cardWrapper {
  padding: 55px 22px 0px 22px;
  border-radius: 22px;
  background-color: #1c1f23;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }
}

.descWrapper {
  display: flex;
}

.cardDesc,
.startDateDesc {
  font: 17px/ 28px var(--visby-medium);
  color: #ffffff;
  opacity: 0.8;
}

.cardDesc {
  font-size: 30px;
  line-height: 58px;
  text-transform: capitalize;
}

.startDateDesc {
  margin-top: 0;
}

.offerLabel {
  font: 17px/ 28px var(--visby-medium);
  color: #ffffff;
  opacity: 0.8;
}

.portfolioWrapper {
  width: 66px;
  height: 66px;
  position: absolute;
  background-color: #00fca3;
  border-radius: 100px;
  z-index: 4;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.portfolioIcon {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 37px;
  height: 32.11px;
  path {
    fill: #252342;
  }
}

.buttonWrapper {
  width: 165px;
  display: flex;
  justify-self: center;
  justify-content: center;
  margin-bottom: 22px;
  button > span {
    font: 13px var(--visby-bold);
  }
  button {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#fff, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#fff, 0.15);
      transform: translateY(0);
    }
  }
}

.strong {
  font-family: var(--visby-bold);
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #292f37;
  padding: 12px;
  border-radius: 50%;
}

.calendarIconWrapper {
  path {
    stroke: rgba(255, 255, 255, 0.7);
  }
}

.rejectOffer {
  path {
    stroke: #00000040;
  }
}

.startDateDesc {
  font: 14px/28px var(--visby);
  color: #ffffff;
  opacity: 0.8;
}
