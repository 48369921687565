.wrapper {
  background-color: #131314;
  color: #ffffff;
  font: 20px/36px var(--visby-semiBold);
  padding: 14px 57px 19px 18px;
  border-radius: 20px;

  @media screen and (max-width: 1020px) {
    font-size: 18px;
  }

  @media screen and (max-width: 923px) {
    font-size: 16px;
  }

  ol > li {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  @media (max-width: 558px) {
    max-width: unset;
    width: 100%;
  }
}

.bulletWrapper {
  background-color: #00fca3;
  width: 34px;
  height: 34px;
  border-radius: 100px;
  position: relative;
  flex-shrink: 0;
}

.bullet {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #252342;
}
