.wrapper {
  width: 1016px;
  @media (max-width: 1280px) {
    width: unset;
    max-width: 1010px;
    margin-top: 46px;
  }
  margin-bottom: 150px;
}

.title,
.titleHighlight {
  font: 52px/58px var(--visby-medium);
  color: #ffffff;
  margin-bottom: 23px;

  @media (max-width: 811px) {
    font: 60px/43px var(--visby-medium);
  }
  @media (max-width: 768px) {
    font: 42px/38px var(--visby-medium);
    margin-bottom: 18px;
    margin-top: 30px;
  }

  @media (max-width: 570px) {
    font: 32px/31px var(--visby-medium);
    margin-bottom: 18px;
    margin-top: 30px;
  }

  @media (max-width: 440px) {
    margin-top: 30px;
  }

  @media (max-width: 320px) {
    font: 20px var(--visby-medium);
    margin-bottom: 18px;
    margin-top: 30px;
  }
}

.titleHighlight {
  color: #ffffff;
}

.subTitle {
  font: 20px/28px var(--visby-medium);
  color: #ffffff;
  width: 700px;
  margin-bottom: 33px;

  @media (max-width: 980px) {
    width: unset;
    max-width: 500px;
    font: 20px var(--visby-medium);
  }

  @media (max-width: 768px) {
    margin-bottom: 28px;
  }

  @media (max-width: 670px) {
    max-width: 400px;
  }

  @media (max-width: 600px) {
    max-width: 320px;
    margin-bottom: 46px;
  }

  @media (max-width: 600px) {
    font: 16px var(--visby-medium);
    margin-bottom: 36px;
  }

  @media (max-width: 440px) {
    max-width: 220px;
  }
}

.desc {
  font: 13px/21px var(--visby);
  color: #ADADAD;
  max-width: 650px;
  @media (max-width: 1045px) {
    max-width: 600px;
  }
  @media (max-width: 520px) {
    font: 15px/24px var(--visby);
  }
}
