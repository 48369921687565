.wrapper {
  max-width: 400px;
  width: 100%;
  z-index: 2;
  margin-right: 40px;
}

.title {
  font: 42px/46px var(--visby-bold);
  color: #ffffff;

  @media (max-width: 580px) {
    font-size: 32px;
    line-height: 36px;
  }
  @media (max-width: 320px) {
    font-size: 28px;
    line-height: 32px;
  }
}

.descWrapper {
  margin-top: 26px;
}

.desc {
  font: 17px/26px var(--visby-medium);
  color: #ffffff;
}
