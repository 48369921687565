.container {
  display: flex;
  flex-direction: row;
  gap: 23px;

  @media (max-width: 1020px) {
    flex-wrap: wrap;
    margin-top: 84px;
  }
  @media (max-width: 990px) {
    margin-top: 44px;
  }
}
.wrapper {
  margin-top: 84px;
  padding: 13px 21px 27px 21px;
  border-radius: 20px;
  width: 295px;
  height: 191px;
  background-color: #121212ed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
  @media (max-width: 1020px) {
    margin-top: 0;
  }
}

.title {
  display: flex;
  font: 20px var(--visby-semiBold);
  color: #ffffff;
}

.value {
  display: flex;
  font: 50px var(--visby-bold);
  color: #00fca3;
  align-self: center;
  position: relative;
}

.currency {
  display: flex;
  font: 31px var(--visby-bold);
  color: #00fca3;
  position: absolute;
  bottom: 22px;
  right: -30px;
}

.link {
  display: flex;
  font: 21px/20px var(--visby-medium);
  a {
    color: #00fca3;
  }
}

.decoration {
  border-bottom: 1px solid #00fca3;
  font: 13px/21px var(--visby);
}
