.container {
  background-color: rgba(0, 0, 0, 0.54);
  border-radius: 20px;
  padding: 16px 25px 28px 25px;
  color: #ffff;
  width: 100%;
  @media (max-width: 340px) {
    padding: 16px;
  }
}

.label {
  font: 13px/21px var(--visby);
}

.item {
  font: 21px/28px var(--visby-medium);
}

.topWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.bottomWrapper {
  background-color: #000000;
  padding: 19px 49px 17px 26px;
  border-radius: 20px;
  margin-top: 19px;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 340px) {
    padding: 19px 16px 17px 20px;
  }
  &:hover {
    transform: translateY(-1px);
  }
}

.disableOpenInvoiceLink {
  &:hover {
    transform: unset;
  }
  pointer-events: none;
}

.status {
  font: 13px/15px var(--visby-medium);
}

.statusDelayed {
  color: #ec4339;
}

.statusClosed {
  color: #00fca3;
}

.statusOpened {
  color: #ffff;
  opacity: 0.5;
}

.invoiceStatus {
  font: 25px/43px var(--visby-bold);
}

.linkWrapper {
  u {
    color: #ffff;
    font: 13px/21px var(--visby-bold);
  }
}

.amountWrapper {
  margin-right: 5px;
}
