.modal {
  & :global(.ant-modal-body) {
    padding: 0px;
  }

  & :global(.ant-modal-close) {
    display: none;
  }

  & :global(.ant-modal-content) {
    background: transparent;
    box-shadow: none;
  }

  &:global(.ant-modal-mask) {
    backdrop-filter: blur(44px);
    background: rgba(28, 31, 35, 0.7);
  }
}
