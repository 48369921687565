.buttonWrapper {
  width: 100%;
}

.wrapper {
  width: 350px;
  height: 50px;
  border-radius: 100px;
  background-color: #ffffff;
  filter: brightness(60%);
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 8px 9px 17px;
  font: 17px var(--visby-semiBold);
  justify-self: start;
  cursor: pointer;
  transition: all 0.2s;
  justify-content: space-between;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }

  &:active {
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }

  @media (max-width: 460px) {
    width: 100%;
  }
}

.iconWrapper {
  width: 33px;
}

.facebookIcon {
  path {
    fill: #3b5999;
  }
}

.githubIcon {
  width: 34px;
  height: 32px;
  path {
    fill: #00204e;
  }
}

.previousButtonWrapper {
  background-color: #00fca3;
  padding: 6px 8px 6px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  color: #00204e;
  font: 9px/10px var(--visby-semiBold);
}

.previousButton {
  text-align: start;
  width: 50px;
}

.highLight {
  filter: brightness(100%);
}

.buttonText {
  @media (max-width: 460px) {
    font-size: 15px;
    line-height: 20px;
  }
  @media (max-width: 385px) {
    font-size: 13px;
    line-height: 15px;
  }
}
