.container {
  width: 781px;
  height: 1102px;
  background-color: #ffffff;
  padding: 44px 50px 36px 50px;
  z-index: 5;
}

.headWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}

.headTitle {
  font: 28px/64px var(--visby-semiBold);
  letter-spacing: 3%;
  color: #252342;
  text-transform: uppercase;
}

.invoiceNr {
  font: 20px/64px var(--visby-medium);
  color: #252342;
}

.codelexLogoWrapper {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 100px;
  background-color: #252342;
  margin-top: 10px;
}

.codelexLogo {
  width: 50.3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  path {
    fill: #00fca3;
  }
}

.contentWrapper {
  column-width: 200px;
  padding-top: 27px;
  display: flex;
  width: 100%;
  border-top: 1px dashed #e1e1e1;
  border-bottom: 1px dashed #e1e1e1;
}

.contentLeftSide,
.contentRightSide {
  width: 100%;
  padding-top: 19px;
  margin-bottom: 27px;
}

.contentLeftSide {
  border-right: 1px dashed #e1e1e1;
  padding-right: 24px;
  width: 341px;
}

.contentRightSide {
  padding-left: 58px;
  width: 341px;
}

.supplier,
.beneficiary,
.accountWrapper,
.address,
.bankWrapper,
.date {
  min-height: 42px;
  margin-bottom: 10px;
}

.account {
  height: 42px;
}

.accountWrapper,
.bankWrapper,
.beneficiary,
.date {
  display: flex;
}

.beneficiary {
  flex-direction: column;
}

.date {
  margin-top: 30px;
}

.contentLabel {
  font:10px var(--visby-bold);
  text-transform: uppercase;
  color: #252342;
}

.contentTitle {
  font: 13px/27px var(--visby);
  color: #252342;
}

.contentName {
  font: 13px/25px var(--visby);
  color: #252342;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  width: 100%;
}

.whereforeWrapper {
  display: flex;
  gap: 41px;
  margin-top: 150px;
}

.watWrapper {
  display: flex;
  gap: 41px;
  margin-top: 26px;
}

.wherefore,
.whereforeSum {
  font: 16px/19px var(--visby-semiBold);
  color: #252342;
}

.wherefore,
.pvn {
  width: 500px;
}

.pvnWrapper {
  display: flex;
  gap: 41px;
  margin-top: 30px;
}

.pvn,
.pvnSum {
  font: 13px/13px var(--visby-medium);
}

.payDetails {
  display: flex;
  height: 77px;
  margin-top: 27px;
}

.payDetailsLeftSide {
  width: 526px;
  height: 100%;
  background-color: #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 22px;
}

.details {
  display: flex;
  gap: 12px;
  height: 35px;
  display: flex;
  align-items: center;
}

.payTitle,
.highLight {
  font: 13px/13px var(--visby);
}

.highLight {
  font-family: var(--visby-semiBold);
}

.payDetailsRightSide {
  background-color: #252342;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 155px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  padding-left: 18px;
  padding-right: 15px;
}

.payLabel {
  color: #ffffff;
  font: 17px/22px var(--visby-bold);
}

.paySum {
  color: #00fca3;
  font: 24px/24px var(--visby-semiBold);
}

.footer {
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigationWrapper {
  display: flex;
  gap: 20px;
}

.navigationHomeWrapper,
.navigationInfoWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.webTitle,
.mailTitle {
  transition: all 0.3s ease-in-out;
  color: #252342;
  font: 11px/11px var(--visby-medium);
  a {
    font: 11px/11px var(--visby-medium);
    color: #252342;
  }
}

.constantWidth {
  width: 140px;
  margin-right: 10px;
}
