.wrapper {
  background-color: #131314;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 11px;
  padding-left: 20px;
  padding-top: 10px;
  border-radius: 20px 0 0 20px;
}
.headerWrapper {
  padding-left: 20px;
}

.timerWrapper {
  margin-top: 71px;
  background-color: #1c1f23;
  border-radius: 10px;
  width: 230px;
  min-height: 300px;
  color: #ffffff;
}

.timer {
  padding-top: 42px;
  font: 71px var(--visby-light);
  padding-left: 21px;
}

.timerText {
  padding: 20px 0 0 16px;
  font: 20px/28px var(--visby-semiBold);
}

.timerTextQusetion {
  color: #00fca3;
}

.exitQuiz {
  padding: 29px 0 0 23px;
}
