.descriptionGroup {
  display: inline-flex;

  @media (max-width: 540px) {
    flex-direction: column;
  }
}

.description {
  font: 13px var(--visby);
  min-width: 97px;
  max-width: 117px;
  color: #ffffff;
  padding: 5px 9px 7px 13px;
  background: #131314;
  border-radius: 6px;
  margin-right: 7px;
  @media (max-width: 590px) {
    font-size: 14px;
  }

  @media (max-width: 540px) {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
