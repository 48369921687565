.wrapper {
  background-color: #131314;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 11px;
  padding-left: 20px;
  padding-top: 10px;
  border-radius: 20px 0 0 20px;
}

.headerWrapper {
  padding-left: 20px;
}

.timerWrapper {
  display: flex;
  margin-top: 71px;
  background-color: #1c1f23;
  border-radius: 10px;
  width: 230px;
  min-height: 280px;
  color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.timerText {
  display: flex;
  font: 20px/28px var(--visby-semiBold);
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
}

.life {
  border-radius: 50%;
  background-color: #ec4339;
  width: 20px;
  height: 20px;
}

.lostLife {
  background-color: transparent;
}

.lifes {
  display: flex;
  align-items: center;
  gap: 10px;
}

.numberWrapper {
  color: #00fca3;
}

.timer {
  padding-top: 42px;
  font: 71px var(--visby-light);
  padding-left: 31px;
}
