.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #131314;
  border-radius: 20px;
  padding: 32px 27px 49px 38px;
}

.title {
  font: 20px/32px var(--visby-bold);
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: 17px;
}

.bottom {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 145px;
  gap: 30px;
  @media (max-width: 290px) {
    padding: 18px 20px 21px 20px;
  }
}

.desc {
  font: 16px/24px var(--visby-medium);
  color: #ADADAD;
}

.actionWrapper {
  display: flex;
  gap: 80px;
  align-items: center;
  margin-top: 47px;
  @media (max-width: 430px) {
    gap: 40px;
  }
}

.button {
  a > span {
  font: 19px  var(--visby-extraBold);
  height: 40px;
  width: 191px;
  text-transform: uppercase;
  border: 3px solid #ec4339;
  color: #ec4339;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  }
}

.buttonWrapper {
  width: 191px;
  a {
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }

    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
  a > span {
    font-family: var(--visby-extraBold);
    text-align: center;
    cursor: pointer;
    }
}

.iconWrapper {
  width: 36px;
  display: flex;
  flex-shrink: 0;
  animation: rotation 5s infinite;
  @media (max-width: 390px) {
    display: none;
  }
  path {
    fill: #00FCA3;
  }
}
@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}
