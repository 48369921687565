.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #131314;
  padding: 22px 26px 29px 25px;
  gap: 24px;
  @media (max-width: 380px) {
    padding: 22px 16px 29px 15px;
  }
}

.headerWrapper {
  display: grid;
  grid-template-columns: 46px auto;
  gap: 16px;
  align-items: center;
}

.title {
  font: 20px/24px var(--visby-medium);
  color: #ffffff;
}

.graphTable {
  overflow: hidden;
  color: #ffffff;
  column-gap: 13px;
  row-gap: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(261px, 1fr));
  @media (max-width: 380px) {
    grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
  }
}

.box {
  display: flex;
  flex-direction: column;
  background-color: #1d1f23;
  border-radius: 10px;
  padding: 8px 0px 12px 16px;
}

.date {
  font: 13px/15px var(--visby-semiBold);
}

.lessonTitle {
  font: 15px/14px var(--visby);
  opacity: 0.8;
}

.time {
  color: #00fca3;
  font: 13px/14px var(--visby);
  margin-top: 6px;
  margin-bottom: 8px;
}

.graphTableHidden {
  max-height: 256px;
}

.showMoreButton {
  background-color: #292f37;
  color: #bfc1c3;
  border: none;
  border-radius: 40px;
  padding: 7px 21px;
  font: 13px var(--visby-bold);
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 31px;
  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
  @media (max-width: 380px) {
    margin-left: 20px;
  }
}

.blurryBackground {
  height: 180px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  width: 370px;
  border-radius: 20px;
  background: linear-gradient(360deg, #131314 40%, rgba(10, 10, 10, 0) 100%);
}
