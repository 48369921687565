.container {
  width: 611px;
  height: 213px;
  background-color: #131314;
  display: flex;
  border-radius: 20px;
  gap: 14px;
  padding: 18px 16px 55px 14px;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 340px;
    height: 100%;
    flex-direction: column;
  }
}

.numberWrapper {
  width: 68px;
  height: 68px;
  background-color: #00fca3;
  position: relative;
  border-radius: 100px;
}

.number {
  font: 36px/54px var(--visby-bold);
  color: #131314;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.firstPartWrapper,
.secondPartWrapper {
  width: 247px;
  height: 137px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 14px 28px 0px 23px;
  display: flex;
  align-self: flex-start;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    background-color: #00fca3;
    & .videoIconWrapper {
      path {
        fill: #fff;
      }
    }
    & .lessonTitle {
      color: #ffffff;
    }
  }
  @media (max-width: 720px) {
    width: 100%;
  }
}

.lessonTitle {
  font: 21/24px var(--visby-medium);
  color: #131314;
  text-align: left;
}

.videoIconWrapper {
  position: absolute;
  width: 31px;
  bottom: 13px;
  right: 13px;
  path {
    fill: #00fca3;
  }
}

.currentLesson {
  color: #ffffff;
}
