.wrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  background: #292d34;
  padding: 5px 10px 5px;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.account {
  font: 12px/20px var(--visby-bold);
  color: #ffffff;
  margin-left: 8px;
  cursor: pointer;
}
