.wrapperConditions {
  margin-top: 46px;
  max-width: 929px;
}

.titleConditions {
  font: 20px/28px var(--visby);
  color: #ffffff;
  word-break: normal;
}

.descConditions {
  font: 13px/21px var(--visby);
  color: #ADADAD;
  h2 {
    font: 20px/28px var(--visby);
    color: #ffffff;
    margin-top: 12px;
    border-bottom: 1px solid #fff;

    @media (max-width: 520px) {
      line-height: 26px;
      margin: 14px 0 28px 0;
    }
  }

  h3 {
    font: 16px/25px var(--visby);
    color: #ffffff;
  }

  em {
    font-family: var(--visby-semiBold);
  }

  ol,
  ul,
  p {
    margin-top: 18px;
  }

  ol > li {
    list-style: decimal;
    list-style-position: inside;
  }

  ul > li {
    list-style: decimal;
    list-style-position: inside;
    margin-left: 20px;
  }
}

.githubWrapper {
  margin-top: 24px;
  max-width: 320px;
}
