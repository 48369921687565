.container {
  display: flex;
  height: 120px;
}

.wrapper {
  position: relative;
  height: 118px;
  width: 68.6px;
  svg {
    height: 118px;
    width: 68.6px;
  }

  @media (max-width: 388px) {
    height: 118px;
    width: 60px;
    svg {
      height: 118px;
      width: 60px;
    }
  }
}

.gap {
  margin-left: 20px;

  @media (max-width: 388px) {
    margin-left: 0;
  }
}

.daysWrapper,
.hoursWrapper {
  display: flex;
  position: relative;
}
.days,
.hours {
  position: absolute;
  bottom: -10px;
  z-index: 5;
  font: 14px/14px var(--visby-medium);
  color: #ffffff;
}
.days {
  left: 49px;
  @media (max-width: 388px) {
    left: 38px;
  }
}
.hours {
  left: 68px;
  @media (max-width: 388px) {
    left: 38px;
  }
}

.number {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  font: 90px/90px var(--visby-semiBold);
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 388px) {
    font-size: 72px;
    line-height: 72px;
  }
}

.numberRed {
  color: #ec4339;
}
