.videoIconWrapper {
  display: flex;
  justify-content: flex-end;
  svg {
    height: 32px;
    path {
      fill: #00fca3;
    }
  }
}

.wrapper {
  width: 100%;
  height: 61px;
  display: grid;
  grid-template-columns: 40px auto 56px;
  padding: 11px 16px 10px 18px;
  background-color: #131314;
  border-radius: 10px;
  align-items: center;
  column-gap: 14px;
  row-gap: 14px;
  @media (max-width: 1000px) {
    height: unset;
    min-height: 61px;
  }
  @media (max-width: 450px) {
    align-items: center;
    row-gap: 0px;
  }
}

.listCountWrapper {
  background-color: #131314;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: relative;
}

.listCount {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #00fca3;
  font: 23px/23px var(--visby-bold);
  padding-top: 1px;
  box-shadow: 0px 10px 20px #000;
  @media (max-width: 450px) {
    top: 50%;
  }
}

.title {
  font: 13px var(--visby);
  color: #ffffff;
  width: 100%;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 450px) {
    grid-column: 2;
    grid-row: 1/3;
    align-items: center;
  }
  @media (max-width: 400px) {
    font: 12px var(--visby);
  }
}

.videoIconWrapper {
  width: 40px;
  margin-left: 16px;
  @media (max-width: 450px) {
    grid-column: 3;
    grid-row: 1;
  }
}

.container {
  cursor: pointer;
  background-color: #141516;
  border-radius: 10px;
  border: 1px solid #141516;
  &:hover {
    .shortDescription {
      max-height: 150px;
      transition: max-height 1s ease;
    }
  }
  &:hover,
  &:active {
    .wrapper {
      background-color: #00fca3;
    }
    .title {
      color: #131314;
    }
    .videoIconWrapper {
      path {
        fill: #131314;
      }
    }
  }
}

.containerActive {
  cursor: not-allowed;
  .wrapper {
    background-color: #00fca3;
  }
  .title {
    color: #131314;
  }
  .videoIconWrapper {
    path {
      fill: #131314;
    }
  }
}
