.dateWithText {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 53px;
}

.dateWrapper {
  padding: 3px 16px 2px 24px;
  width: 200px;
  background: #00fca3;
  border-radius: 20px;
  border: none;
  color: #252342;
  text-transform: uppercase;
  font: 12px var(--visby-extraBold);
  margin-bottom: 0;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;

  @media (max-width: 430px) {
    font: 10px var(--visby-extraBold);
  }
}

.startText {
  font: 12px var(--visby-bold);
  color: #00fca3;
  margin-bottom: 2px;

  @media (max-width: 430px) {
    font: 10px var(--visby-bold);
  }
}
