.wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  font: 16px/39px var(--visby-bold);
  color: #ffffff;
  &:hover {
    cursor: pointer;
    path {
      fill: #00fca3;
    }
  }
  svg {
    width: 39px;
    height: 29px;
  }
}
