.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 26px;

  @media (max-width: 1079px) {
    flex-direction: column;
  }
}

.wrapper {
  padding: 21px 47px 24px 33px;
  border: 1px solid transparent;
  border-radius: 20px;
  max-width: 800px;
  background-color: #131618;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 600px) {
    gap: 16px;
  }

  @media (max-width: 400px) {
    padding: 21px 26px 24px 18px;
    width: 100%;
    height: 100%;
  }
}

.descWrapper {
  display: flex;
}

.desc {
  font: 16px/28px var(--visby-medium);
  color: #ADADAD;
  @media (max-width: 900px) {
    font: 18px/24px var(--visby-medium);
  }
  @media (max-width: 365px) {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.copyWrapper {
  display: grid;
  grid-template-columns: 26px auto;
  gap: 11px;
  align-items: center;
  @media (max-width: 370px) {
    max-width: 100px;
  }
}

.copyIcon {
  display: flex;
  width: 26px;
  height: 35px;
  path {
    fill: #ADADAD;
  }
}

.copyText {
  display: flex;
  font: 19px var(--visby-medium);
  color: #ADADAD;
}
