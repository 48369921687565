.wrapper {
  position: relative;
  width: 455px;
  height: 298px;
  padding: 29px 36px 51px 38px;
  border-radius: 20px;
  background-color: #131314;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 1100px) {
    width: 655px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconWrapper {
  width: 105px;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: rotate(20deg) translate(50%, -50%);
  filter: drop-shadow(0 0 32px #00fca3);

  path {
    fill: #00fca3;
  }

  @media (max-width: 800px) {
    top: 30%;
  }
  @media (max-width: 420px) {
    top: 40%;
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.buttonWrapper {
  margin-top: 21px;
  width: 165px;
  transition: all 0.2s;

  button {
    span {
      font: 13px var(--visby-bold);
    }
  }
  @media (max-width: 460px) {
    margin-top: 26px;
    width: 100%;
    height: 100%;
    position: unset;
  }
}

.title {
  font: 20px/28px var(--visby-semiBold);
  color: #ffffff;
}

.descWrapper {
  margin-top: 7px;
  max-width: 520px;
  @media (max-width: 1100px) {
    max-width: unset;
    width: 100%;
  }

  @media (max-width: 480px) {
    padding-right: 70px;
  }
}

.desc {
  margin-top: 7px;
  font: 13px/21px var(--visby);
  color: #ADADAD;
  max-width: 300px;
}
