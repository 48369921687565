.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-items: center;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.spinner div {
  left: 96px;
  top: 15px;
  position: absolute;
  animation: spinner linear 1.3157894736842106s infinite;
  background: #00fca3;
  width: 8px;
  height: 22px;
  border-radius: 4px / 5.0600000000000005px;
  transform-origin: 4px 85px;
}
.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.25s;
  background: #00fca3;
}
.spinner div:nth-child(2) {
  transform: rotate(18deg);
  animation-delay: -1.1842105263157896s;
  background: #00fca3;
}
.spinner div:nth-child(3) {
  transform: rotate(36deg);
  animation-delay: -1.118421052631579s;
  background: #00fca3;
}
.spinner div:nth-child(4) {
  transform: rotate(54deg);
  animation-delay: -1.0526315789473684s;
  background: #00fca3;
}
.spinner div:nth-child(5) {
  transform: rotate(72deg);
  animation-delay: -0.986842105263158s;
  background: #00fca3;
}
.spinner div:nth-child(6) {
  transform: rotate(90deg);
  animation-delay: -0.9210526315789475s;
  background: #00fca3;
}
.spinner div:nth-child(7) {
  transform: rotate(108deg);
  animation-delay: -0.8552631578947368s;
  background: #00fca3;
}
.spinner div:nth-child(8) {
  transform: rotate(126deg);
  animation-delay: -0.7894736842105263s;
  background: #00fca3;
}
.spinner div:nth-child(9) {
  transform: rotate(144deg);
  animation-delay: -0.7236842105263158s;
  background: #00fca3;
}
.spinner div:nth-child(10) {
  transform: rotate(162deg);
  animation-delay: -0.6578947368421053s;
  background: #00fca3;
}
.spinner div:nth-child(11) {
  transform: rotate(180deg);
  animation-delay: -0.5921052631578948s;
  background: #00fca3;
}
.spinner div:nth-child(12) {
  transform: rotate(198deg);
  animation-delay: -0.5263157894736842s;
  background: #00fca3;
}
.spinner div:nth-child(13) {
  transform: rotate(216deg);
  animation-delay: -0.46052631578947373s;
  background: #00fca3;
}
.spinner div:nth-child(14) {
  transform: rotate(234deg);
  animation-delay: -0.39473684210526316s;
  background: #00fca3;
}
.spinner div:nth-child(15) {
  transform: rotate(252deg);
  animation-delay: -0.32894736842105265s;
  background: #00fca3;
}
.spinner div:nth-child(16) {
  transform: rotate(270deg);
  animation-delay: -0.2631578947368421s;
  background: #00fca3;
}
.spinner div:nth-child(17) {
  transform: rotate(288deg);
  animation-delay: -0.19736842105263158s;
  background: #00fca3;
}
.spinner div:nth-child(18) {
  transform: rotate(306deg);
  animation-delay: -0.13157894736842105s;
  background: #00fca3;
}
.spinner div:nth-child(19) {
  transform: rotate(324deg);
  animation-delay: -0.06578947368421052s;
  background: #00fca3;
}
.spinner div:nth-child(20) {
  transform: rotate(342deg);
  animation-delay: 0s;
  background: #00fca3;
}
.spinnerWrapper {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.spinner div {
  box-sizing: content-box;
}
