.wrapper {
  position: relative;
  cursor: pointer;
}

.previewWrapper {
  width: 230px;
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 14px;
  align-content: center;
  padding-left: 6px;
  &:hover {
    background-color: #00fca3;
    border-radius: 4px;
    max-width: 285px;
    h3 {
      color: #131314;
    }
    .preview {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg)
        brightness(11%) contrast(105%);
    }
  }
}
.title {
  font: 13px/39px var(--visby);
  color: #00fca3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
}

.preview {
  @media (min-width: 768px) {
    max-height: 405px;
    width: 21px;
  }
  @media (min-width: 1280px) {
    max-height: unset;
  }
}

.iframeWrapper {
  width: 95%;
  height: 300px;
  @media (min-width: 375px) {
    min-width: 350px;
    height: 260px;
  }
  @media (min-width: 425px) {
    min-width: 400px;
  }
  @media (min-width: 768px) {
    min-width: 650px;
    height: 365px;
  }
  @media (min-width: 1024px) {
    height: 490px;
  }
  @media (min-width: 1280px) {
    width: 966px;
    width: 90%;
    height: 550px;
    margin: 0 auto;
  }
}

.close {
  background-color: #131618;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  right: -86px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1200px) {
    top: 75px;
    right: 25px;
  }
  @media (max-width: 640px) {
    top: 50px;
    right: 5px;
  }
}

.modal {
  & :global(.ant-modal-content) {
    border-radius: 30px;
    background-color: transparent;
  }
  & :global(.ant-modal-body) {
    padding: 0;
  }
  & :global(.ant-modal-close) {
    color: #ffffff;
  }
  & :global(.ant-modal-close-x) {
    font: 18px var(--visby-bold);
    padding-left: 20px;
    margin-right: 10px;
    @media (max-width: 380px) {
      margin-right: 0;
    }
  }
}