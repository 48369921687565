.btnWrapper {
  margin-top: 12px;
  height: 38px;
  min-width: 353px;

  @media (max-width: 490px) {
    min-width: 343px;
  }
  @media (max-width: 480px) {
    min-width: 333px;
  }
  @media (max-width: 470px) {
    min-width: 323px;
  }
  @media (max-width: 460px) {
    min-width: 313px;
  }
  @media (max-width: 450px) {
    min-width: 303px;
  }
  @media (max-width: 440px) {
    min-width: 293px;
  }
  @media (max-width: 430px) {
    min-width: 283px;
  }
  @media (max-width: 420px) {
    min-width: 273px;
  }
  @media (max-width: 410px) {
    min-width: 263px;
  }
  @media (max-width: 400px) {
    min-width: 253px;
  }

  @media (max-width: 390px) {
    min-width: 243px;
  }
  @media (max-width: 380px) {
    min-width: 233px;
  }
  @media (max-width: 370px) {
    min-width: 223px;
  }
  @media (max-width: 360px) {
    min-width: 213px;
  }

  @media (max-width: 350px) {
    min-width: 203px;
  }
}
