.container {
  @media (min-width: 780px) {
    display: flex;
    justify-content: center;
  }
}

.wrapper {
  width: 781px;
  height: 1102px;
  background-color: #ffffff;
  padding: 44px 50px 36px 50px;
  z-index: 5;
}

.headWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
}
.headTitle {
  font: 28px/64px var(--visby-semiBold);
  letter-spacing: 3%;
  color: #252342;
  text-transform: uppercase;
}

.invoiceNr {
  font: 20px/64px var(--visby-medium);
  color: #252342;
}

.codelexLogoWrapper {
  position: relative;
  width: 96px;
  height: 96px;
  border-radius: 100px;
  background-color: #252342;
}
.codelexLogo {
  width: 50.3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  path {
    fill: #00fca3;
  }
}

.contentWrapper {
  column-width: 200px;
  padding-top: 27px;

  display: flex;
  width: 100%;

  border-top: 1px dashed #e1e1e1;
  border-bottom: 1px dashed #e1e1e1;
}

.contentLeftSide,
.contentRightSide {
  width: 100%;
  padding-top: 19px;
  margin-bottom: 27px;
}

.contentLeftSide {
  border-right: 1px dashed #e1e1e1;
  padding-right: 24px;
  width: 341px;
}

.contentRightSide {
  padding-left: 58px;
  width: 341px;
}
.supplier,
.accountWrapper,
.adress,
.bankWrapper,
.date {
  height: 42px;
  margin-bottom: 10px;
}

.account {
  height: 42px;
}

.accountWrapper,
.bankWrapper,
.date {
  display: flex;
}

.contentLabel {
  font: 10px var(--visby-bold);
  text-transform: uppercase;
  color: #252342;
}

.contentTitle {
  font: 13px/25px var(--visby);
  color: #252342;
}

.contentName {
  font: 13px/25px var(--visby);
  color: #252342;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  max-width: 140px;
  width: 100%;
}

.whereforeWrapper {
  display: flex;
  gap: 41px;
  margin-top: 46px;
}

.wherefore,
.whereforeSum {
  font: 16px/19px var(--visby-semiBold);
  color: #252342;
}

.wherefore,
.pvn {
  width: 500px;
}

.pvnWrapper {
  display: flex;
  gap: 41px;
  margin-top: 30px;
}

.pvn,
.pvnSum {
  font: 13px/13px var(--visby-medium);
}

.payDetails {
  display: flex;
  height: 77px;
  margin-top: 27px;
}

.payDetailsLeftSide {
  width: 526px;
  height: 100%;

  background-color: #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 22px;
}

.details {
  display: flex;
  gap: 12px;
  height: 35px;
  display: flex;
  align-items: center;
}

.payTitle,
.highLight {
  font: 13px/13px var(--visby);
}

.highLight {
  font-family: var(--visby-semiBold);
}

.payDetailsRightSide {
  background-color: #252342;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 155px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
  padding-left: 18px;
  padding-right: 15px;
}

.payLabel {
  color: #ffffff;
  font: 17px/22px var(--visby-bold);
}

.paySum {
  color: #00fca3;
  font: 24px/24px var(--visby-semiBold);
}

.banner {
  width: 684px;
  height: 140px;
  margin-top: 128px;
}

.bannerTop {
  background-color: #00fca3;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 86px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  gap: 20px;
}

.bannerBottom {
  gap: 20px;
  background-color: #252342;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 21px;
  height: 86px;
  display: flex;
  align-items: center;
}
.bannerTopTitle {
  width: 510px;
}
.bannerBottomTitle {
  width: 520px;
}

.bannerTopTitle {
  font: 13px/18px var(--visby);
  color: #252342;
}

.bannerBottomTitle {
  font: 13px/18px var(--visby);
  color: #ffffff;
}

.bannerTopHighLight {
  a {
    transition: all 0.3s ease-in-out;
    color: #252342;
    font: 13px/18px var(--visby-semiBold);
    word-break: break-all;
    &:hover {
      color: #ffffff;
    }
    &:active {
      color: #ffffff;
    }
  }
}

.bannerBottomHighLight {
  transition: all 0.3s ease-in-out;
  a {
    font: 13px/18px var(--visby-semiBold);
    color: #ffffff;
    word-break: break-all;
  }
}

.bannerIconWrapper {
  width: 37px;
}

.footer {
  margin-top: 48px;
  height: 19.26px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.navigationWrapper {
  display: flex;
  gap: 20px;
}

.navigationHomeWrapper,
.navigationInfoWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.webTitle,
.mailTitle {
  transition: all 0.3s ease-in-out;
  color: #252342;
  font: 11px/11px var(--visby-medium);
  a {
    font: 11px/11px var(--visby-medium);
    color: #252342;
  }
}

.constantWidth {
  width: 140px;
  margin-right: 10px;
}

.buttonWrapper {
  width: 175px;
  margin-bottom: 30px;
  button {
    span {
      font: 13px var(--visby-bold);
    }
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 10px 20px rgba(#000, 0.2);
    }
    &:active {
      box-shadow: 0 5px 10px rgba(#000, 0.15);
      transform: translateY(0);
    }
  }
}
