.wrapper {
  display: flex;
  gap: 15px;
}

.customButton {
  display: flex;
  text-align: start;
  font: 16px var(--visby-semiBold);
  line-height: 16px;
  font-weight: 700;
  width: 100%;
  min-height: 51px;
  max-width: 226px;
  align-items: center;
  color: #2f2f2f;
  background-color: #00fca3;
  border: 1px solid #00fca3;
  border-radius: 10px;
  min-height: 38px;
  padding: 14px 0 14px 17px;
  cursor: pointer;
  transition: all 0.2s;
  .discordSvg {
    display: flex;
    width: 34px;
    height: 26px;
    filter: brightness(18%);
  }
  &:hover {
    color: #00fca3;
    color: #2f2f2f;
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(#fff, 0.2);
  }
  &:active {
    color: #00fca3;
    color: #2f2f2f;
    box-shadow: 0 5px 10px rgba(#fff, 0.15);
    transform: translateY(0);
  }
}

.disabled {
  background-color: #262627;
  cursor: not-allowed;
  color: #ffffff;

  &:hover {
    transform: none;
    box-shadow: none;
    color: #ffffff;
  }
  .discordSvg {
    filter: none;
  }
}

.buttonText {
  font-size: 10px;
}
